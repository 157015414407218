var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"fixed-header":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.computedItems,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.items === null)?_c('v-btn',{staticClass:"my-5",attrs:{"text":"","color":"primary","loading":_vm.loading},on:{"click":_vm.loadTransactions}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Carregar movimentação ")],1):_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.noDataText")))])]},proxy:true},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(value,"L"))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(value,"LLL")))])])]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [(value === 'purchase')?_c('span',[_vm._v("Nota fiscal recebida")]):(value === 'update_cost')?_c('span',[_vm._v("Ajuste custo médio")]):(value === 'inventory')?_c('span',[_vm._v("Inventário")]):(value === 'outgoing')?_c('span',[_vm._v("Saída")]):(value === 'outgoing_cancelation')?_c('span',[_vm._v(" Saída ‐ "),_c('span',{staticClass:"secondary--text text--lighten-2"},[_vm._v("Cancelada")])]):(value === 'incoming')?_c('span',[_vm._v("Entrada")]):(value === 'incoming_cancelation')?_c('span',[_vm._v(" Entrada ‐ "),_c('span',{staticClass:"secondary--text text--lighten-2"},[_vm._v("Cancelada")])]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.reference_id",fn:function(ref){
var item = ref.item;
return [(item.type === 'purchase')?[_c('span',{staticClass:"font-weight-medium mr-1"},[_vm._v("Chave de acesso:")]),_vm._l((item.reference_id.match(/.{4}/g)),function(part){return _c('span',{key:part,staticClass:"pr-1",domProps:{"textContent":_vm._s(part)}})})]:(
        [
          'outgoing',
          'outgoing_cancelation',
          'incoming',
          'incoming_cancelation' ].includes(item.type)
      )?[_c('span',{staticClass:"font-weight-medium mr-1"},[_vm._v(_vm._s(item.nfModel))]),_c('span',[_vm._v(_vm._s(item.nfSerie)+" / "+_vm._s(item.nfNumber))])]:_c('span',[_vm._v(_vm._s(item.reference_id))]),_c('div',{staticClass:"font-weight-light",class:{
        'secondary--text text--lighten-2': ![
          'update_cost',
          'inventory' ].includes(item.type),
      }},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.created_by)+" ")],1)]}},{key:"item.amount",fn:function(ref){
      var value = ref.value;
return [(value)?_c('number',{attrs:{"value":value}}):_c('span',[_vm._v("--")])]}},{key:"item.cost_price",fn:function(ref){
      var value = ref.value;
return [(value)?_c('currency',{attrs:{"hide-symbol":"","value":value}}):_c('span',[_vm._v("--")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }