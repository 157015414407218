<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-card-title class="text-h6 font-weight-medium flex-grow-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                exact
                class="mr-2"
                to="/cadastros/produtos"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>

            <span>Voltar para lista</span>
          </v-tooltip>

          <span v-if="value.id">
            Produto #{{ value.id }} - {{ value.description }}
          </span>
          <span v-else>Novo Produto</span>
        </v-card-title>
      </v-col>

      <v-col cols="12" v-if="value.removed_at">
        <v-alert
          outlined
          prominent
          border="left"
          color="error"
          icon="mdi-delete-empty"
        >
          <v-card-title
            class="font-weight-medium"
            style="word-break: break-word"
          >
            Produto removido em {{ value.removed_at | moment("LLL") }}
          </v-card-title>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-card outlined :loading="saving">
          <v-form ref="form">
            <v-card-title class="section-title">
              Dados de Gerais
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col v-if="value.id" cols="12" sm="2">
                  <v-text-field
                    outlined
                    disabled
                    hide-details
                    label="Código"
                    placeholder="000"
                    v-model="value.id"
                  />
                </v-col>

                <v-col cols="12" :sm="value.id ? 10 : 12">
                  <v-text-field
                    required
                    outlined
                    hide-details="auto"
                    label="Nome do produto"
                    :disabled="!!value.removed_at"
                    :rules="[notEmptyRule]"
                    v-model="value.description"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    required
                    outlined
                    hide-details
                    label="Código de barras (EAN)"
                    :disabled="!!value.removed_at"
                    v-model="value.barcode"
                  />
                </v-col>

                <v-col cols="12" sm="3">
                  <unit-field
                    required
                    outlined
                    hide-details="auto"
                    :disabled="!!value.removed_at"
                    :rules="[notEmptyRule]"
                    v-model="value.unit"
                  />
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <number-field
                    required
                    outlined
                    hide-details
                    label="Peso Líquido"
                    suffix="KG"
                    :precision="4"
                    :disabled="!!value.removed_at"
                    v-model="value.weight"
                  />
                </v-col>

                <v-col cols="12" md="4" sm="3">
                  <v-select
                    required
                    outlined
                    label="IPPT"
                    hide-details="auto"
                    :disabled="!!value.removed_at"
                    :items="[
                      { value: true, text: 'P - Produção própria' },
                      { value: false, text: 'T - Produzido por terceiros' },
                    ]"
                    :rules="[notEmptyRule]"
                    v-model="value.own_product"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="2" sm="3">
                  <number-field
                    required
                    outlined
                    hide-details="auto"
                    label="Preço de venda"
                    :disabled="!!value.removed_at"
                    :prefix="value.sale_price ? 'R$' : undefined"
                    :rules="[minDecimalRule(0.01, value.sale_price)]"
                    v-model="value.sale_price"
                  >
                    <template v-slot:label>
                      Preço de venda <sup>1</sup>
                    </template>
                  </number-field>
                </v-col>

                <template v-if="!!value.id">
                  <v-col cols="12" md="2" sm="3">
                    <number-field
                      required
                      outlined
                      disabled
                      hide-details
                      label="Custo médio"
                      :prefix="value.cost_price ? 'R$' : undefined"
                      v-model="value.cost_price"
                    >
                      <template v-slot:label>Custo médio <sup>1</sup></template>
                    </number-field>
                  </v-col>

                  <v-col v-if="!value.removed_at" align-self="center">
                    <a @click="newTransactionDialog = value">
                      Nova movimentação
                    </a>
                  </v-col>
                </template>
              </v-row>

              <v-row v-if="!!value.id">
                <v-col cols="12" md="2" sm="3">
                  <number-field
                    disabled
                    outlined
                    hide-details
                    label="Estoque disponível"
                    :suffix="value.unit"
                    :precision="4"
                    :value="stock"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <sup>1</sup>
                  <i>
                    Para cálculos de preço de venda e custo médio será utilizado
                    <strong>T - Truncamento</strong>
                  </i>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-title class="section-title">
              Dados de Fiscais
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="auto">
                  <v-switch
                    required
                    hide-details
                    label="Produto com substituição tributária"
                    class="mt-0"
                    :disabled="!!value.removed_at"
                    v-model="value.tax_substitution"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6" md="8">
                  <ncm-field
                    required
                    outlined
                    hide-details="auto"
                    :disabled="!!value.removed_at"
                    :rules="[notEmptyRule]"
                    v-model="value.ncm"
                  />
                </v-col>

                <v-col v-if="value.tax_substitution" cols="12" lg="6" md="8">
                  <cest-field
                    required
                    outlined
                    hide-details
                    v-model="value.cest"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <origin-field
                    required
                    outlined
                    hide-details="auto"
                    :disabled="!!value.removed_at"
                    :rules="[notEmptyRule]"
                    v-model="value.origin"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <template v-if="value.id">
            <v-card-title class="section-title">
              Movimentação do produto
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <transactions-table
                    :product-id="value.id"
                    :unit="value.unit"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <v-card-actions
            class="py-12 px-sm-8 justify-center justify-sm-space-between"
          >
            <v-btn outlined class="d-none d-sm-flex" @click="$router.back()">
              Voltar
            </v-btn>

            <div v-if="!value.removed_at">
              <v-tooltip bottom v-if="!!value.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    tile
                    color="error"
                    class="mx-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="remove"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>

                <span>Remover produto</span>
              </v-tooltip>

              <v-btn large color="success" :loading="saving" @click="save">
                <v-icon left>mdi-content-save-outline</v-icon>
                Salvar produto
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <new-transaction
      v-if="!value.removed_at"
      :value="newTransactionDialog"
      @save="newTransactionClosed"
      @close="newTransactionClosed"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Decimal from "decimal.js";
import $store from "@/store";
import Rules from "@/mixins/Rules";
import GoTo from "@/mixins/GoTo";
import CestField from "@/components/CestField";
import NcmField from "@/components/NcmField";
import NewTransaction from "@/components/Product/NewTransaction";
import NumberField from "@/components/NumberField";
import OriginField from "@/components/Product/OriginField";
import TransactionsTable from "@/components/Product/TransactionsTable";
import UnitField from "@/components/Product/UnitField";

export default {
  name: "RecordsProductView",
  metaInfo() {
    return {
      title: this.value.id ? "Produto #" + this.value.id : "Novo produto",
    };
  },
  mixins: [Rules, GoTo],
  components: {
    NumberField,
    NcmField,
    CestField,
    OriginField,
    UnitField,
    NewTransaction,
    TransactionsTable,
  },
  data: () => ({
    newTransactionDialog: null,
    saving: false,
    value: {
      type: "product",
      own_product: true,
      stocks: [],
    },
  }),
  computed: {
    stock() {
      const stock = this.value.stocks.find((s) => s.current_company);
      return stock?.amount || 0;
    },
    ...mapState("auth", ["company"]),
  },
  watch: {
    async company() {
      const { id } = this.value;
      if (id) {
        this.value = await this.$store.dispatch("products/get", id);
      }
    },
  },
  methods: {
    validate() {
      const { form } = this.$refs;
      if (form.validate()) {
        return true;
      }

      this.notifyError("Alguns campos obrigatórios não foram preenchidos 😢");
      return false;
    },
    async save() {
      this.saving = true;
      if (!this.validate()) {
        this.saving = false;
        return;
      }

      let method,
        url = "/v1/products";
      if (this.value.id) {
        url += `/${this.value.id}`;
        method = "put";
      } else {
        method = "post";
      }

      if (!this.value.tax_substitution) {
        delete this.value.cest;
      }

      try {
        const { data } = await this.$http[method](url, this.value);

        if (!this.value.id) {
          this.$router.push({
            name: "product-view",
            params: { id: data.id },
          });
        } else {
          this.goTo(0);
        }

        this.value = data;
        this.notifySuccess("Produto salvo com sucesso 🎉");
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao salvar o Produto 😢");
      } finally {
        this.saving = false;
      }
    },
    async remove() {
      const { id, description } = this.value;

      const msg = `Tem certeza que deseja deletar o Produto #${id} - ${description}?`;
      if (!confirm(msg)) {
        return;
      }

      try {
        await this.$http.delete(`/v1/products/${id}`);
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao deletar o Produto 😢");
      }
      this.$router.push({ name: "product-list" });
    },
    newTransactionClosed(data) {
      this.newTransactionDialog = null;
      if (!data) {
        return;
      }
      if (data.costPrice) {
        this.value.cost_price = data.costPrice;
      }
      if (data.amount) {
        let stock = this.value.stocks.find((s) => s.current_company);
        if (!stock) {
          stock = {
            amount: 0,
            company_id: this.company.id,
            current_company: true,
          };
          this.value.stocks.push(stock);
        }
        let amount = new Decimal(stock.amount);
        stock.amount = amount.add(data.amount).toNumber();
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      let product = await $store.dispatch("products/get", id);
      next((vm) => {
        vm.$nextTick().then(() => {
          vm.value = product;
        });
      });
    } catch (err) {
      next({ name: "product-list" });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      this.value = await this.$store.dispatch("products/get", id);
      next();
    } catch (err) {
      next({ name: "product-list" });
    }
  },
};
</script>
