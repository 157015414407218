<template>
  <v-data-table
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="computedItems"
    :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:no-data>
      <v-btn
        v-if="items === null"
        text
        color="primary"
        class="my-5"
        :loading="loading"
        @click="loadTransactions"
      >
        <v-icon left>mdi-reload</v-icon>
        Carregar movimentação
      </v-btn>

      <span v-else>{{ $vuetify.lang.t("$vuetify.noDataText") }}</span>
    </template>

    <template v-slot:item.date="{ value }">
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ value | moment("L") }}
          </span>
        </template>

        <span>{{ value | moment("LLL") }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.type="{ value }">
      <span v-if="value === 'purchase'">Nota fiscal recebida</span>
      <span v-else-if="value === 'update_cost'">Ajuste custo médio</span>
      <span v-else-if="value === 'inventory'">Inventário</span>
      <span v-else-if="value === 'outgoing'">Saída</span>
      <span v-else-if="value === 'outgoing_cancelation'">
        Saída &dash;
        <span class="secondary--text text--lighten-2">Cancelada</span>
      </span>
      <span v-else-if="value === 'incoming'">Entrada</span>
      <span v-else-if="value === 'incoming_cancelation'">
        Entrada &dash;
        <span class="secondary--text text--lighten-2">Cancelada</span>
      </span>
      <span v-else>{{ value }}</span>
    </template>

    <template v-slot:item.reference_id="{ item }">
      <template v-if="item.type === 'purchase'">
        <span class="font-weight-medium mr-1">Chave de acesso:</span>
        <span
          class="pr-1"
          v-for="part in item.reference_id.match(/.{4}/g)"
          :key="part"
          v-text="part"
        />
      </template>

      <template
        v-else-if="
          [
            'outgoing',
            'outgoing_cancelation',
            'incoming',
            'incoming_cancelation',
          ].includes(item.type)
        "
      >
        <span class="font-weight-medium mr-1">{{ item.nfModel }}</span>
        <span>{{ item.nfSerie }} / {{ item.nfNumber }}</span>
      </template>

      <span v-else>{{ item.reference_id }}</span>
      <div
        class="font-weight-light"
        :class="{
          'secondary--text text--lighten-2': ![
            'update_cost',
            'inventory',
          ].includes(item.type),
        }"
      >
        <v-icon small>mdi-account</v-icon>
        {{ item.created_by }}
      </div>
    </template>

    <template v-slot:item.amount="{ value }">
      <number v-if="value" :value="value" />
      <span v-else>--</span>
    </template>

    <template v-slot:item.cost_price="{ value }">
      <currency v-if="value" hide-symbol :value="value" />
      <span v-else>--</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import Currency from "@/components/Currency";
import Number from "@/components/Number";

export default {
  name: "ProductTransactionsTable",
  components: {
    Number,
    Currency,
  },
  props: {
    productId: [String, Number],
    unit: String,
  },
  data: () => ({
    loading: false,
    items: null,
  }),
  computed: {
    computedItems() {
      return this.items || [];
    },
    headers() {
      return [
        {
          text: "Data",
          value: "date",
          width: 100,
        },
        {
          text: "Tipo de transação",
          value: "type",
          width: 160,
        },
        {
          text: "Referência",
          value: "reference_id",
        },
        {
          text: `Estoque (${this.unit})`,
          value: "amount",
          align: "right",
          width: 106,
          filterable: false,
          sortable: false,
        },
        {
          text: "Preço de custo (R$)",
          value: "cost_price",
          align: "right",
          width: 150,
          filterable: false,
          sortable: false,
        },
      ];
    },
    ...mapState("auth", ["company"]),
  },
  watch: {
    company() {
      this.$nextTick().then(() => {
        if (this.items !== null) {
          this.loadTransactions();
        }
      });
    },
  },
  methods: {
    async loadTransactions() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(
          `/v1/products/${this.productId}/transactions`
        );
        this.items = data.items.map((t) => {
          if (t.reference_id) {
            var [nfModel, nfSerie, nfNumber] = t.reference_id.split("/");
            switch (nfModel) {
              case "nfce":
                nfModel = "NFC-e";
                break;
              case "nfe":
                nfModel = "NF-e";
                break;
            }
          }
          return { ...t, nfModel, nfSerie, nfNumber };
        });
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError(
          "Ocorreu um erro ao carregar lista de Movimentação 😢"
        );
      }
      this.loading = false;
    },
  },
};
</script>
