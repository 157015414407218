<template>
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="computedWidth"
    v-model="show"
  >
    <v-card :loading="loading">
      <v-card-title class="text-button">
        Nova movimentação
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  disabled
                  hide-details
                  label="Código do produto"
                  v-model="product.id"
                />
              </v-col>

              <v-col cols="12" sm="9">
                <v-text-field
                  outlined
                  disabled
                  hide-details
                  label="Nome do produto"
                  v-model="product.description"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="5">
                <date-picker-field
                  outlined
                  disabled
                  hide-details
                  label="Data da movimentação"
                  :value="new Date().toISOString().substr(0, 10)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  outlined
                  hide-details
                  label="Tipo de movimentação"
                  :items="types"
                  :rules="[notEmptyRule]"
                  v-model="type"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  disabled
                  hide-details
                  label="Motivo"
                  value="Inventario"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <number-field
                  outlined
                  hide-details
                  label="Quantidade"
                  placeholder="0,0000"
                  :required="!amountRules.length"
                  :disabled="!amountRules.length"
                  :suffix="product.unit"
                  :precision="{
                    min: 0,
                    max: 4,
                  }"
                  :rules="amountRules"
                  v-model="amount"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <number-field
                  outlined
                  hide-details
                  label="Custo médio"
                  :prefix="averageCost ? 'R$' : undefined"
                  :required="!averageCostRules.length"
                  :disabled="!averageCostRules.length"
                  :rules="averageCostRules"
                  v-model="averageCost"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-6 justify-center">
        <v-btn depressed @click="$emit('close')">
          Cancelar
        </v-btn>

        <v-btn large color="success" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Decimal from "decimal.js";
import Rules from "@/mixins/Rules";
import DatePickerField from "@/components/DatePickerField.vue";
import NumberField from "@/components/NumberField.vue";

export default {
  name: "ProductNewTransaction",
  mixins: [Rules],
  components: {
    DatePickerField,
    NumberField,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      loading: false,
      amount: "",
      averageCost: "",
      type: "",
    };
  },
  computed: {
    product() {
      return this.value || {};
    },
    show: {
      get() {
        return !!this.value;
      },
      set(v) {
        if (!v) {
          this.$emit("close");
        }
      },
    },
    computedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "45%";
        case "xl":
          return "35%";
      }
      return "auto";
    },
    types() {
      return [
        { value: "in", text: "Entrada" },
        { value: "out", text: "Saída" },
        { value: "costPrice", text: "Ajustar Custo Médio" },
      ];
    },
    amountRules() {
      if (["in", "out"].includes(this.type)) {
        return [this.minDecimalRule(0.0001, this.amount)];
      }
      return [];
    },
    averageCostRules() {
      if (this.type === "costPrice") {
        return [this.minDecimalRule(0.01, this.averageCost)];
      }
      return [];
    },
  },
  watch: {
    value(v) {
      if (!v) {
        this.type = this.amount = this.averageCost = "";
      } else {
        this.averageCost = v.cost_price;
      }
    },
    type(newv, oldv) {
      const newType = ["in", "out"].includes(newv) ? "stock" : newv;
      const oldType = ["in", "out"].includes(oldv) ? "stock" : oldv;
      if (newType != oldType) {
        this.amount = "";
        this.averageCost = "";
      }
    },
  },
  methods: {
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      let type, costPrice, amount;
      switch (this.type) {
        case "in":
          type = "inventory";
          amount = this.amount;
          break;
        case "out":
          type = "inventory";
          amount = new Decimal(this.amount).mul(-1).toNumber();
          break;
        case "costPrice":
          type = "update_cost";
          costPrice = this.averageCost;
          break;
      }

      try {
        await this.$http.post(`/v1/products/${this.product.id}/transactions`, {
          date: new Date().toISOString(),
          type,
          cost_price: costPrice,
          amount,
        });
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao tentar salvar a movimentação 😢");
      }

      this.loading = false;
      this.$nextTick().then(() => {
        this.$emit("save", { amount, costPrice });
      });
    },
  },
};
</script>
