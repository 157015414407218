<template>
  <v-select
    :item-text="(item) => item.value + ' - ' + item.text"
    :label="label"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "ProductOriginField",
  props: {
    label: {
      type: String,
      default: "Origem",
    },
  },
  computed: {
    items() {
      return [
        {
          value: 0,
          text: "Nacional",
        },
        {
          value: 1,
          text: "Estrangeira – Importação direta",
        },
        {
          value: 2,
          text: "Estrangeira – Adquirida no mercado interno",
        },
        {
          value: 3,
          text:
            "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%",
        },
        {
          value: 4,
          text:
            "Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos (PPB) de que tratam o Decreto-Lei nº 288/1967, e as Leis nºs 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007",
        },
        {
          value: 5,
          text:
            "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
        },
        {
          value: 6,
          text:
            "Estrangeira – Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural",
        },
        {
          value: 7,
          text:
            "Estrangeira – Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural",
        },
        {
          value: 8,
          text:
            "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
        },
      ];
    },
  },
};
</script>
